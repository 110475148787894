import React, { useReducer, useMemo, useContext } from 'react'
import { Button, Modal, Row, Col } from 'antd'
import { simpleReducer } from '../../../../helper'
import PhotoViewer from '../PhotoViewer/PhotoViewer'
import { AppConfig } from '../../../../AppConfig'
import { profileScreen } from '../../../../translates'
import { ConfigContext } from '../../../../context/configContext'

const initialState = {
  isOpenModal: false
}

function DocumentDoubleView ({ profile, loading, onCommit }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { isCoordinators, isCoordinator } = useContext(ConfigContext)

  const handleOpen = () => {
    setState({ isOpenModal: true })
  }
  const handleCancel = () => {
    setState({ isOpenModal: false })
  }

  const documentList = useMemo(() => {
    return AppConfig.documentNameList.filter(d => ![
      profileScreen.input.lmkMainPhoto.name,
      profileScreen.input.lmkAllowancePhoto.name,
      profileScreen.input.lmkAttestationPhoto.name
    ].includes(d) || (
      [
        profileScreen.input.lmkMainPhoto.name,
        profileScreen.input.lmkAllowancePhoto.name,
        profileScreen.input.lmkAttestationPhoto.name
      ].includes(d) && !isCoordinators && !isCoordinator
    ))
  }, [AppConfig.documentNameList, isCoordinators, isCoordinator])

  return (
    <>
      <Button onClick={handleOpen} style={{ marginLeft: '1rem' }} size='small'>Сравнить документы</Button>
      <Modal
        title='Сравнение документов'
        visible={state.isOpenModal}
        width='90%'
        style={{ top: 20, height: '70vh' }}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Row style={{ height: '80vh' }}>
          <Col span={12}>
            <PhotoViewer
              componentName='first'
              documents={documentList}
              profile={profile}
              loading={loading}
              onCommitDocument={onCommit}
            />
          </Col>
          <Col span={12}>
            <PhotoViewer
              componentName='second'
              documents={documentList}
              profile={profile}
              disableApproval
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handleCancel}>Закрыть</Button>
        </div>
      </Modal>
    </>
  )
}

export default DocumentDoubleView
