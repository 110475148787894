import React, { useState, useContext, useEffect } from 'react'
import { Layout, message, Spin } from 'antd'
import { useLocation, useHistory, generatePath } from 'react-router-dom'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons'
import './AdminLayout.css'

import AdminLogin from '../AdminLogin/AdminLogin'
import LeftMenu from '../LeftMenu/LeftMenu'
import { ConfigContext } from '../../../context/configContext'
import { useGetProfileQuery } from '../../../api/react-query/users'
import { AppConfig } from '../../../AppConfig'
import AdminPartnersStats from '../AdminPartnersStats/AdminPartnersStats'
import {
  ADMIN_LMK_REPORT_PAGE,
  ADMIN_PARTNERS_CONTENT_PAGE,
  ADMIN_PARTNERS_PAGE,
  ADMIN_PARTNERS_RESEARCHER_PAGE
} from '../../../routes'

const { Header, Content, Sider } = Layout

function AdminLayout (props) {
  const [collapsed, setCollapsed] = useState(JSON.parse(localStorage.getItem('menuCollapsed')))
  const { setConfig, user } = useContext(ConfigContext)
  const location = useLocation()
  const history = useHistory()

  const {
    data: profile,
    isError,
    isSuccess,
    isLoading
  } = useGetProfileQuery({
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (isSuccess && JSON.stringify(profile?.user) !== JSON.stringify(user)) {
      setConfig({
        user: profile.user,
        isAdmin: profile.user?.groupName === AppConfig.groups.administrators,
        isCoordinators: profile.user?.groupName === AppConfig.groups.coordinators,
        isCoordinator: profile.user?.groupName === AppConfig.groups.coordinator,
        isSupervisor: profile.user?.groupName === AppConfig.groups.supervisors,
        isPersonalManager: profile.user?.groupName === AppConfig.groups.personalmanagers,
        isLmkController: profile.user?.groupName === AppConfig.groups.lmkcontrollers,
        isContentCoordinator: profile.user?.groupName === AppConfig.groups.contentcoordinators,
        isCityManager: profile.user?.groupName === AppConfig.groups.citymanagers,
        isResearcherCoordinator: profile.user?.groupName === AppConfig.groups.researchercoordinators,
        isPartnerSupport: profile.user?.groupName === AppConfig.groups.partnersupport,
        isDivisionalInstructor: profile.user?.groupName === AppConfig.groups.divisionalinstructors,
        isExternalCoordinator: profile.user?.groupName === AppConfig.groups.externalcoordinators,
        isOperation: profile.user?.groupName === AppConfig.groups.operations,
        isSecurity: profile.user?.groupName === AppConfig.groups.security
      })
      if (location.pathname.startsWith(ADMIN_PARTNERS_PAGE) && profile.user?.groupName === AppConfig.groups.lmkcontrollers) {
        history.replace(generatePath(ADMIN_LMK_REPORT_PAGE))
      }
      if (location.pathname.startsWith(ADMIN_PARTNERS_PAGE) && profile.user?.groupName === AppConfig.groups.contentcoordinators) {
        history.replace(generatePath(ADMIN_PARTNERS_CONTENT_PAGE))
      }
      if (location.pathname.startsWith(ADMIN_PARTNERS_PAGE) && profile.user?.groupName === AppConfig.groups.researchercoordinators) {
        history.replace(generatePath(ADMIN_PARTNERS_RESEARCHER_PAGE))
      }
    }
    if (isSuccess && !profile?.isSuccess) {
      message.error(profile?.errorMessage || 'Ошибка доступа к данным')
    }
  }, [isSuccess, setConfig, profile?.user, user, location.pathname])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка получения профиля')
    }
  }, [isError])

  const toggle = () => {
    setCollapsed(!collapsed)
    localStorage.setItem('menuCollapsed', !collapsed)
  }

  return (
    <Spin spinning={isLoading}>
      {Object.values(user || {}).length > 0 && !isLoading &&
        <Layout className='AdminLayout'>
          <Sider
            breakpoint='lg'
            collapsedWidth='0'
            trigger={null}
            collapsible
            collapsed={collapsed} width={248}
          >
            <div style={{ padding: '0 24px', backgroundColor: 'white' }}>
              <div className='logo' style={{ backgroundImage: 'url("/img/logo-sbl-3.png")' }} />
            </div>
            <LeftMenu authRole='Admin' userRole={user?.groupName} />
          </Sider>
          <Layout>
            <Header className='site-layout-sub-header-background'>
              {
                collapsed
                  ? (
                    <>
                      <div className='logo' style={{ width: '200px', display: 'inline-block', backgroundImage: 'url("/img/logo-sbl-3.png")' }} />
                      <MenuUnfoldOutlined className='trigger' onClick={toggle} style={{ marginLeft: 20 }} />
                    </>
                  )
                  : <MenuFoldOutlined className='trigger' onClick={toggle} />
              }
              <div className='content'>
                <AdminPartnersStats />
                <AdminLogin />
              </div>
            </Header>
            <Content>
              <div className='site-layout-background' style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24, textAlign: 'left' }}>
                {
                  props.children
                }
              </div>
            </Content>
          </Layout>
        </Layout>}
    </Spin>
  )
}

export default AdminLayout
