import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { Alert, Button, Form, Layout, message, PageHeader, Row, Spin, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import ErrorBlock from '../../../components/Partner/Profile/ErrorBlock'
import {
  apiErrorCodes,
  AppConfig,
  partnerFormStatusCodes,
  partnerStatusCodes
} from '../../../AppConfig'
import {
  CitySelectorFormItem,
  EmailFormItem,
  // InnFormItem,
  NameFormItem,
  PassportBlock,
  RegistrationAddressBlock
} from '../../../components/Common'
import { errors, /* offerScreen, */ profileContractScreen, profileScreen } from '../../../translates'
import {
  // arrayUniqValues,
  createApiDataFromForm,
  // flattenObject2,
  // restoreFlattenObject,
  // validateInn,
  validateName,
  validateAllPhotosUploading,
  // validateFullInn,
  // isDisabledMedicalAcceptOffer,
  // renderMedicalOfferTemplate,
  // digestMessage,
  // getMedicalOfferParams,
  simpleReducer
  // getMedicalAcceptOfferErrors
} from '../../../helper'
import MiddleNameFormItem from '../../../components/Common/FormItems/MiddleNameFormItem'
import './ProfileEditInfoScreenStyle.css'
import '../../../components/Partner/Profile/ProfileStyle.css'
import { LeftOutlined } from '@ant-design/icons'
import BankDetails from '../../../components/Partner/Profile/BankDetails'
import { useStores } from '../../../Store/Store'
import {
  useGetEditProfileForm,
  // useGetInitialEditProfileForm,
  // useGetMedicalAcceptOffer,
  useMutateCreateEditInfoRequest
  // useMutateMedicalOfferAccept
} from '../../../api/react-query/partners'
import { formLayout/*, partnerLinkedFields */ } from '../../../Constants'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import Documents from '../../../components/Partner/Profile/Documents'
import { PROFILE_EDITINFO_STATE_INIT } from './ProfileMainScreen'
// import MedicalAcceptOffer from '../../../components/Common/FormItems/Medical/MedicalAcceptOffer'
// import { fromEditTypes } from '../../../components/Partner/PolicyAgreement/PolicyAgreement'
// import LegalAgeCapableItem from '../../../components/Common/FormItems/LegalAgeCapableItem'
import DrivingDetails from '../../../components/Partner/Profile/DrivingDetails'
import GenderSelectFormItem from '../../../components/Common/FormItems/GenderSelectFormItem'

const { Content } = Layout
const { Title, Text } = Typography

const initialState = {
  registrationKindCode: null,
  isLegalAgeCapable: null
}

function ProfileEditInfoScreen ({ extState, setExtState }) {
  const history = useHistory()
  const [form] = Form.useForm()
  const [isTouched, setIsTouched] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [trigger, setTrigger] = useState(false)
  const [sessionChangedFields, setSessionChangedFields] = useState([])
  // const [disabledMedicalAcceptOffer, setDisabledMedicalAcceptOffer] = useState(false)
  // const [errorsMedicalAcceptOffer, setErrorsMedicalAcceptOffer] = useState([])
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    partnerProfile: {
      profile,
      id,
      required,
      setPartnerProfile,
      form: profileForm
    } = {}
  } = useStores()

  // const isSzContent = useMemo(() => {
  //   return profile?.personalData?.roleCode === AppConfig.partnerRoles.szContent
  // }, [profile?.personalData?.roleCode])
  //
  // const isResearcher = useMemo(() => {
  //   return profile?.personalData?.roleCode === AppConfig.partnerRoles.researcher
  // }, [profile?.personalData?.roleCode])

  const { data: editProfileFormData } = useGetEditProfileForm({
    enabled: !!id,
    refetchOnWindowFocus: false
  })
  // const { data: initialProfileFormData } = useGetInitialEditProfileForm({
  //   enabled: [partnerEditInfoStatusCodes.none].includes(profileFormStatus),
  //   refetchOnWindowFocus: false
  // })
  const fillForm = useCallback((formData, profile) => {
    setPartnerProfile({
      profile: {
        ...profile,
        form: formData?.form
      }
    })
  }, [setPartnerProfile])

  // useEffect(() => {
  //   if (initialProfileFormData) {
  //     fillForm(initialProfileFormData, profile)
  //   }
  // }, [initialProfileFormData, fillForm])

  useEffect(() => {
    if (editProfileFormData) {
      fillForm(editProfileFormData, profile)
    }
  }, [editProfileFormData, fillForm])

  const { mutate, data, isLoading, isSuccess, isError } = useMutateCreateEditInfoRequest()
  const editInfo = useMemo(() => profileForm.editInfo, [profileForm.editInfo])

  useEffect(() => {
    if (Object.keys(editInfo || {}).length && !form.getFieldValue(['personalData', profileScreen.input.firstName.name])?.length) {
      form.setFieldsValue(extState?.form ?? editInfo)

      const country = form.getFieldValue(['personalData', 'registrationAddress', 'country'])
      form.setFieldsValue({
        personalData: {
          registrationAddress: { country: country ?? profileScreen.input.registrationAddressCountry.placeholder },
          [profileScreen.input.registrationKind.name]: editInfo?.personalData?.registrationKindCode
        }
      })

      setSessionChangedFields(extState?.sessionChangedFields)
      setTrigger(Math.random())
    }
  }, [form, editInfo, extState?.form, extState?.sessionChangedFields])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка отправки реквизитов.')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      if (data?.errorMessage) {
        if (data?.errorCode === apiErrorCodes.innDuplicate) {
          setBackendFieldsErrors({ inn: [data?.errorMessage] })
        }
        return message.error(data?.errorMessage || 'Ошибка отправки реквизитов.')
      }
      if (Object.keys(data?.profile || {}).length) {
        setPartnerProfile({ profile: data.profile })
      }
      message.success('Ваша анкета отправлена на проверку.')
      setExtState(PROFILE_EDITINFO_STATE_INIT)
    }
  }, [data, isSuccess, setExtState])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors, form])

  // const {
  //   data: dataMedicalOffer,
  //   isError: isErrorMedicalOffer
  // } = useGetMedicalAcceptOffer({
  //   refetchOnWindowFocus: false
  // })

  // useEffect(() => {
  //   if (isErrorMedicalOffer) {
  //     message.error(offerScreen.errors.offer)
  //   }
  // }, [isErrorMedicalOffer])

  // const {
  //   mutateAsync: acceptMedicalOffer,
  //   data: dataAcceptMedicalOffer,
  //   isLoading: isLoadingAcceptMedicalOffer,
  //   isError: isErrorAcceptMedicalOffer
  // } = useMutateMedicalOfferAccept()
  //
  // useEffect(() => {
  //   if (isErrorAcceptMedicalOffer || dataAcceptMedicalOffer?.errorMessage) {
  //     message.error(dataAcceptMedicalOffer?.errorMessage || errors.acceptLmkAgreement)
  //   }
  // }, [isErrorAcceptMedicalOffer, dataAcceptMedicalOffer?.errorMessage])

  // const acceptMedicalOfferAsync = async function (offerData, profile, onSuccess) {
  //   const content = renderMedicalOfferTemplate(offerData?.template?.content, profile)
  //   const hash = await digestMessage(content)
  //   const result = await acceptMedicalOffer({
  //     templateId: offerData?.template?.id,
  //     signHash: hash,
  //     signData: getMedicalOfferParams(profile)
  //   })
  //   if (result?.isSuccess) {
  //     onSuccess?.()
  //   }
  // }

  const onFinish = (values) => {
    setBackendFieldsErrors({})
    // if (!values.medicalAcceptOffer && profile?.lmkDetails?.hasMedicalBook && !(isResearcher || isSzContent)) {
    //   return
    // }

    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }

    form.validateFields().then(values => {
      const data = createApiDataFromForm(values)
      const bankDetailsPhoto = data.bankDetails?.bankDetailsPhoto
      delete data.personalData.birthDate
      delete data.bankDetails?.bankDetailsPhoto

      const successEditInfo = () => {
        mutate({
          personalDetails: data.personalData,
          bankDetails: data.bankDetails,
          bankDetailsPhoto,
          drivingLicencePhoto1: data.drivingLicencePhoto1,
          drivingLicencePhoto2: data.drivingLicencePhoto2,
          passportPhoto1: data.passportPhoto1,
          passportPhoto2: data.passportPhoto2,
          passportPhotoTmpReg: data.passportPhotoTmpReg,
          passportPhotoExtraReg: data.passportPhotoExtraReg,
          city: data.city,
          // innPhoto: data.innPhoto,
          // vaccinacionPhoto: data.vaccinacionPhoto,
          egripPhoto: data.egripPhoto,
          trusteeAgreement: data.trusteeAgreement,
          trusteeConfirmation: data.trusteeConfirmation,
          legalCapacityConfirmation: data.legalCapacityConfirmation,
          trusteePassport: data.trusteePassport
        })
      }
      // if (!(isResearcher || isSzContent) && values.medicalAcceptOffer && profile?.lmkDetails?.hasMedicalBook) {
      //   acceptMedicalOfferAsync(
      //     dataMedicalOffer,
      //     { personalData: data.personalData },
      //     successEditInfo
      //   )
      // } else {
      successEditInfo()
      // }
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  /**
   * Для поля которое меняется определяем связанные поля, которые надо почистить.
   * Из списка связанных полей выкидываем те поля которые уже менялись юзером за эту сессию. Их чистить уже не надо.
   * */
  // const getFieldsNeedToClean = useCallback((fieldFullName) => {
  //   const sessionTmpChangedFields = arrayUniqValues([...sessionChangedFields, fieldFullName])
  //   const linkedFields = partnerLinkedFields[fieldFullName] || {}
  //   const flattenLinkedFields = flattenObject2(linkedFields)
  //   const notChangedFields = Object.keys(flattenLinkedFields).filter(value => !sessionTmpChangedFields.includes(value))
  //   Object.keys(flattenLinkedFields)
  //     .filter(key => !notChangedFields.includes(key))
  //     .forEach(deleteKey => delete flattenLinkedFields[deleteKey])
  //
  //   setSessionChangedFields(arrayUniqValues([...sessionTmpChangedFields, ...notChangedFields]))
  //   const restoredLinkedFields = restoreFlattenObject(flattenLinkedFields)
  //   return (Object.keys(restoredLinkedFields || {}).length) ? restoredLinkedFields : false
  // }, [sessionChangedFields])

  const handleUpdateForm = useCallback((changedFields = false) => {
    if (!isTouched && changedFields?.length) {
      setIsTouched(true)
    }

    /**
     * при сабмите формы этот метод вызываетя тоже в массиве полей сразу все поля одновременно
     * алгоритм очистки связанных полей должен работать только когда меняют одно поле,
     * т.е. в момент изменений юзером, а не в момент обработки сабмита
     */
    // if (changedFields?.length === 1) {
    //   changedFields.forEach(field => {
    //     if (Array.isArray(field.name)) {
    //       const needCleanFields = getFieldsNeedToClean(field.name.join('.'))
    //       if (needCleanFields) {
    //         form.setFieldsValue(needCleanFields)
    //         setTrigger(Math.random())
    //       }
    //     }
    //   })
    // }

    const values = form.getFieldsValue()
    // const medicalData = { personalData: values?.personalData }
    // setDisabledMedicalAcceptOffer(isDisabledMedicalAcceptOffer(medicalData))
    // setErrorsMedicalAcceptOffer(getMedicalAcceptOfferErrors(medicalData))

    setState({
      registrationKindCode: values?.personalData?.registrationKind,
      isLegalAgeCapable: values?.personalData?.isLegalAgeCapable
    })
  }, [isTouched, form])

  // useEffect(() => {
  //   const values = form.getFieldsValue()
  //   const medicalData = { personalData: values?.personalData }
  //   setDisabledMedicalAcceptOffer(isDisabledMedicalAcceptOffer(medicalData))
  //   setErrorsMedicalAcceptOffer(getMedicalAcceptOfferErrors(medicalData))
  // }, [trigger, form])

  if (profile?.statusCode && profile?.statusCode !== partnerStatusCodes.loginIssued) {
    history.push(PROFILE_MAIN_PAGE)
  }
  const handleUpdateImage = useCallback((item, info) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: info.fileList })
    setTrigger(Math.random())
  }, [form])

  const handleDeleteImage = useCallback((item) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: [] })
    setTrigger(Math.random())
  }, [form])

  const handleGoOutFromPage = useCallback(() => {
    setExtState({ form: form.getFieldsValue(), sessionChangedFields })
  }, [form, setExtState, sessionChangedFields])

  const filterDocuments = useCallback((d) =>
    editInfo?.[d] && editInfo?.visibleInForm?.[d]
  , [editInfo]
  )

  const isShowLegalAgeCapable = useMemo(() => {
    return profileForm?.personalData?.isLegalAgeCapable === false || profileForm?.personalData?.isLegalAgeCapable === true
  }, [profileForm?.personalData])

  return (
    <Layout className='ProfileEditInfoWrapper'>
      <MobileHeader />

      <Spin spinning={!profileForm?.statusCode || isLoading} size='large'>{/* || isLoadingAcceptMedicalOffer */}
        <Content className='Profile'>
          <Title level={4} className='mt-3'>Изменить анкетные данные</Title>
          <Row className='mt-3 mb-3'>
            <Button
              type='text' onClick={() => {
                setExtState(PROFILE_EDITINFO_STATE_INIT)
                history.goBack()
              }}
            >
              <LeftOutlined /> {profileContractScreen.button.back}
            </Button>
          </Row>
          {[partnerFormStatusCodes.verification].includes(editInfo?.statusCode)
            ? <Title level={5} className='mt-4'>Ваша анкета отправлена на проверку</Title>
            : editInfo?.statusCode &&
              <div>
                {
                  editInfo?.statusCode === partnerFormStatusCodes.declined &&
                    <Alert
                      message={<Text type='secondary'>Причины отказа</Text>}
                      description={<div className='pre-line'>{editInfo?.statusReason}</div>}
                      type='error'
                    />
                }
                <PageHeader title={profileScreen.title} />
                <Form
                  {...formLayout}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={handleUpdateForm}
                  form={form}
                  layout='vertical'
                  scrollToFirstError
                >
                  <ErrorBlock />
                  <NameFormItem
                    name={['personalData', 'surname']}
                    placeholder={profileScreen.input.surname.placeholder}
                    rules={[{ required: true, message: profileScreen.input.surname.message, validator: validateName }]}
                    label={profileScreen.input.surname.label}
                  />
                  <NameFormItem
                    name={['personalData', 'firstName']}
                    placeholder={profileScreen.input.firstName.placeholder}
                    rules={[{ required: true, message: profileScreen.input.firstName.message, validator: validateName }]}
                    label={profileScreen.input.firstName.label}
                  />
                  <MiddleNameFormItem form={form} trigger={trigger} />

                  {!profile?.personalData?.gender && <GenderSelectFormItem />}
                  {/* {isShowLegalAgeCapable && <LegalAgeCapableItem />} */}
                  <EmailFormItem />

                  <CitySelectorFormItem
                    rules={[{ required: required?.personalData, message: profileScreen.input.city.message }]}
                    // onChange={handleChangeCity}
                  />

                  <PassportBlock />
                  {/* <InnFormItem */}
                  {/*  label={profileScreen.input.inn.label} */}
                  {/*  placeholder={profileScreen.input.inn.placeholder} */}
                  {/*  rules={[ */}
                  {/*    { required: true, message: profileScreen.input.inn.message, validator: validateInn }, */}
                  {/*    { message: errors.innCheckSum, validator: (rule, value) => validateFullInn({ rule, inn: value }) } */}
                  {/*  ]} */}
                  {/*  classNameInput='noScrollNumber' */}
                  {/* /> */}
                  <BankDetails isVisible={!profile?.externalBankDetails} form={form} isEditInfoScreen />

                  <RegistrationAddressBlock
                    initRegistrationKind={state.registrationKindCode}
                    registrationFlow={profile?.registrationFlow /* && !(isSzContent || isResearcher) */ && profile?.personalData?.hasNfc}
                    required={{
                      zipCode: true,
                      country: true,
                      region: true,
                      street: true,
                      house: true
                    }}
                    disabled={{ country: true }}
                  />

                  <DrivingDetails
                    form={form}
                    trigger={trigger}
                    handleUpdateImage={handleUpdateImage}
                    // handleUploadingImage={handleUploadingImage}
                    handleDeleteImage={handleDeleteImage}
                    handleUpdateForm={handleUpdateForm}
                    filterDocuments={filterDocuments}
                    showInputs
                  />

                  <Documents
                    trigger={trigger}
                    form={form}
                    required={{
                      passportPhoto1: true,
                      passportPhoto2: true,
                      passportPhotoTmpReg: /* profile?.registrationFlow && */ state.registrationKindCode === AppConfig.registrationTypes.temporary,
                      innPhoto: true,
                      bankDetailsPhoto: true,
                      vaccinacionPhoto: !!profileForm?.vaccinacionPhoto?.[0]?.response?.fileId,
                      egripPhoto: true,
                      trusteeAgreement: true,
                      trusteeConfirmation: true,
                      legalCapacityConfirmation: true,
                      trusteePassport: true
                    }}
                    filterDocuments={filterDocuments}
                    documents={[
                      'passportPhoto1',
                      'passportPhoto2',
                      ...((/* isSzContent || isResearcher || */ !profile?.personalData?.hasNfc) ? [] : ['passportPhotoExtraReg']),
                      ...(profile?.registrationFlow && profile?.personalData?.hasNfc && state.registrationKindCode === AppConfig.registrationTypes.temporary ? ['passportPhotoTmpReg'] : []),
                      'innPhoto',
                      ...(profile?.externalBankDetails ? [] : ['bankDetailsPhoto']),
                      'egripPhoto',
                      'trusteeAgreement',
                      ...(isShowLegalAgeCapable && state.isLegalAgeCapable ? [] : ['trusteePassport']),
                      ...(isShowLegalAgeCapable && !state.isLegalAgeCapable ? ['trusteeConfirmation'] : []),
                      ...(isShowLegalAgeCapable && state.isLegalAgeCapable ? ['legalCapacityConfirmation'] : [])
                      // ...((isSzContent || isResearcher) ? [] : ['vaccinacionPhoto'])
                    ]}
                    onUpdate={handleUpdateImage}
                    onDelete={handleDeleteImage}
                    onPhotoDoc={handleGoOutFromPage}
                    disabledUploadModal={(/* isSzContent || isResearcher || */ !profile?.personalData?.hasNfc)}
                  />

                  {/* { */}
                  {/*  !(isResearcher || isSzContent) && profile?.lmkDetails?.hasMedicalBook && ( */}
                  {/*    <MedicalAcceptOffer */}
                  {/*      disabled={disabledMedicalAcceptOffer} */}
                  {/*      errors={errorsMedicalAcceptOffer} */}
                  {/*      onMedicalOfferShow={handleGoOutFromPage} */}
                  {/*      fromEdit={fromEditTypes.editInfo} */}
                  {/*    /> */}
                  {/*  ) */}
                  {/* } */}

                  <Button
                    htmlType='submit' type='primary' size='large' className='mt-3' block
                    loading={isLoading} //  || isLoadingAcceptMedicalOffer
                    disabled={!isTouched}
                  >Отправить
                  </Button>
                  <Button block size='large' className='mt-3' onClick={() => history.goBack()} disabled={isLoading}>Отмена</Button>{/* || isLoadingAcceptMedicalOffer */}
                </Form>
              </div>}
        </Content>
      </Spin>
    </Layout>
  )
}

export default ProfileEditInfoScreen
