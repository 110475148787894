import React, { useMemo } from 'react'
import { Col, List } from 'antd'
import moment from 'moment-timezone'
import { AppConfig } from '../../../AppConfig'
import { generatePath, useHistory } from 'react-router-dom'
import { ADMIN_PARTNER_PAGE } from '../../../routes'

const validList = [
  AppConfig.partnerStatTypes.waitingPartners,
  AppConfig.partnerStatTypes.verifiedPartners
]

function PartnerItem ({ item, statType }) {
  const history = useHistory()

  const span = useMemo(() => {
    return validList.includes(statType) ? 5 : 8
  }, [statType])

  const handleClick = () => {
    if (item?.partnerId) {
      history.replace(generatePath(ADMIN_PARTNER_PAGE, { id: item.partnerId }))
    }
  }

  return (
    <List.Item>
      <Col span={span}>
        <a onClick={handleClick}>{item.partnerName}</a>
      </Col>
      {validList.includes(statType) && (
        <Col span={span}>
          {item.status}
        </Col>
      )}
      <Col span={span}>{item.legalForm}</Col>
      <Col span={span}>
        {
          item.statusTimestamp
            ? moment(item.statusTimestamp).format(AppConfig.formats.shortDateAndTime)
            : ''
        }
      </Col>
      {validList.includes(statType) && (
        /* если пустое значение надо показать столбик поэтому нужен span */
        <Col span={!item.userName && 4}>
          {item.userName}
        </Col>
      )}
    </List.Item>
  )
}

export default PartnerItem
