import React, { useCallback, useEffect, useMemo, useReducer, useContext } from 'react'
import { Button, Col, DatePicker, Form, message, Row, Select, Typography, Divider } from 'antd'
import moment from 'moment-timezone'

import './Report.css'
import { simpleReducer, sortByName } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { useChains } from '../../../hooks'
import { useOrgTreeQuery } from '../../../api/react-query/org'
import ReportList from '../../../components/Admin/Reports/ReportList'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import { ConfigContext } from '../../../context/configContext'
import ButtonWithDelay from './ButtonWithDelay'

const { Title } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const now = moment()
const initialStart = moment().subtract(6, 'days')
const selectSearchFilter = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export default function AssetsReport () {
  const { isPersonalManager } = useContext(ConfigContext)
  const [form] = Form.useForm()
  const initialState = {
    start: initialStart,
    end: now,
    cities: [],
    chains: [],
    selectedCities: [],
    selectedChains: [],
    filteredChains: [],
    isLoadingOldReport: false,
    currentLoadingReportId: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isSuccess: isSuccessOrgTree,
    isError: isErrorOrgTree,
    data: dataOrgTree,
    isLoading: isLoadingOrgTree
  } = useOrgTreeQuery({
    withEmptyCity: true,
    withClosed: true,
    withOpened: true
  })

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(AppConfig.report.types.assets)

  const {
    mutate: addAssetReportToQueue,
    isError: isErrorAddAssetReportToQueue,
    data: dataAddAssetReportToQueue,
    isLoading: isLoadingAddAssetReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isSuccessOrgTree && dataOrgTree?.isSuccess) {
      const cities = sortByName(dataOrgTree?.cities)
      setState({ cities })
    }
    if (isErrorOrgTree || dataOrgTree?.errorMessage) {
      message.error(dataOrgTree?.errorMessage || dataOrgTree?.title || 'Ошибка доступа к данным')
    }
  }, [dataOrgTree, isSuccessOrgTree, isErrorOrgTree])

  useEffect(() => {
    if (isErrorAddAssetReportToQueue || dataAddAssetReportToQueue?.errorMessage) {
      message.error(dataAddAssetReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddAssetReportToQueue, dataAddAssetReportToQueue])

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const { data: chains } = useChains()
  useEffect(() => {
    if (chains) {
      setState({ chains, filteredChains: chains })
    }
  }, [chains])

  const handleChangeCities = useCallback(
    (value, items) => {
      setState({
        selectedCities: items.map(e => e.key),
        filteredChains: items.length
          ? state.cities
            .filter(city => items.map(e => e.key).includes(city.id))
            .reduce((prev, curr) => {
              const chainsIds = prev.map(chain => chain.id)
              curr.chains.map(chain => {
                if (!chainsIds.includes(chain.id)) {
                  prev.push(chain)
                }
              })
              return prev
            }, [])
          : state.chains,
        selectedChains: []
      })
      form.resetFields(['chains'])
    },
    [form, state]
  )
  const handleChangeChains = useCallback(
    (value, item) => setState({ selectedChains: item.map(e => e.key) }),
    []
  )

  const datePickerDefaultValue = useMemo(() => [state.start, state.end], [state.start, state.end])

  const getReportQueryData = (variant) => ({
    cityIds: state.selectedCities.length ? state.selectedCities : undefined,
    chainIds: state.selectedChains.length ? state.selectedChains : undefined,
    minCreationDate: moment(state.start)
      .set({ hour: 0, minute: 0, second: 0 })
      .format(AppConfig.formats.shortDateAndTimeApi),
    maxCreationDate: moment(state.end)
      .set({ hour: 24, minute: 0, second: 0 })
      .format(AppConfig.formats.shortDateAndTimeApi),
    variant
  })

  const handleAddReportToQueue = (reportVersion) => {
    addAssetReportToQueue({
      type: AppConfig.report.types.assets,
      data: getReportQueryData(reportVersion)
    })
  }

  const handleChangeFilter = ([start, end]) => setState({ start, end })

  return (
    <>
      <Title level={3} className='title-management'>Отчет по имуществу</Title>
      <Form
        form={form}
      >

        <FilterTitle title='Период'>
          <Form.Item>
            <RangePicker
              defaultValue={datePickerDefaultValue}
              onChange={handleChangeFilter}
            />
          </Form.Item>
        </FilterTitle>

        {!isPersonalManager && (
          <>
            <FilterTitle title='Город'>
              <Form.Item name='cities'>
                <Select
                  loading={isLoadingOrgTree}
                  onChange={handleChangeCities}
                  size='middle'
                  placeholder='Выберите город'
                  showSearch
                  optionFilterProp='children'
                  filterOption={selectSearchFilter}
                  allowClear
                  mode='multiple'
                >
                  {state.cities?.map(city => (
                    <Option key={city.id} value={city.name}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </FilterTitle>

            <FilterTitle title='Сеть'>
              <Form.Item name='chains'>
                <Select
                  onChange={handleChangeChains}
                  size='middle'
                  placeholder='Выберите сеть'
                  showSearch
                  filterOption={selectSearchFilter}
                  allowClear
                  mode='multiple'
                >
                  {state.filteredChains?.map(chain => (
                    <Option key={chain.id} value={chain.name}>
                      {chain.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </FilterTitle>
          </>
        )}

        <Row>
          <Col className='mr-3'>
            <ButtonWithDelay
              loading={isLoadingAddAssetReportToQueue}
              htmlType='button'
              onClick={() => handleAddReportToQueue('v2')}
            />
          </Col>
          <Col className='mr-3'>
            <Button
              loading={isLoadingAddAssetReportToQueue}
              htmlType='button'
              onClick={() => handleAddReportToQueue('v1')}
            >
              Отчет старого образца
            </Button>
          </Col>
        </Row>
      </Form>
      {
        dataReportsQueue?.items &&
          <>
            <Divider />
            <Row className='mt-3' gutter={15} align='middle'>
              <Col>
                <Title level={5}>Отчеты</Title>
              </Col>
              <Col>
                <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
              </Col>
            </Row>
            <ReportList
              isLoadingReports={isLoadingReportsQueue}
              dataReports={dataReportsQueue?.items}
            />
          </>
      }
    </>
  )
}
