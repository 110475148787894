import React from 'react'
import { Col, Row } from 'antd'

function CoordinatorHeader () {
  return (
    <Row>
      <Col span={9}>ФИО</Col>
      <Col span={5}>Время авторизации</Col>
      <Col span={5}>На портале</Col>
      <Col span={5}>Форма занятости</Col>
      {/* <Col span={6}>Города</Col> */}
      {/* <Col span={3}>Приоритет анкеты</Col> */}
    </Row>
  )
}

export default CoordinatorHeader
