import { Button } from 'antd'
import React, { useState } from 'react'

export default function ButtonWithDelay ({
  type = 'primary',
  htmlType = 'submit',
  loading = false,
  text = 'Запросить отчет',
  onClick
}) {
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = () => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 2000)
    onClick?.()
  }
  return (
    <Button
      type={type}
      htmlType={htmlType}
      loading={loading || isLoading}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}
