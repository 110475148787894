import React, { useContext, useEffect } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { generatePath, useHistory } from 'react-router-dom'
import { useMutateAdminNewPartnerVerification } from '../../../api/react-query/adminPartners'
import { ADMIN_PARTNER_PAGE } from '../../../routes'
import { Button, message } from 'antd'

function AssignNewPartnerButton () {
  const { isCoordinators, isCoordinator, isExternalCoordinator, isPartnerSupport, user } = useContext(ConfigContext)
  const history = useHistory()

  const {
    mutate: assignPartner,
    data: dataPartnerVerification,
    isSuccess: isSuccessVerification,
    isError: isErrorVerification,
    isLoading: isLoadingVerification,
    error: errorVerification
  } = useMutateAdminNewPartnerVerification()

  useEffect(() => {
    if (isSuccessVerification && dataPartnerVerification?.isSuccess) {
      history.replace(generatePath(ADMIN_PARTNER_PAGE, { id: dataPartnerVerification.partnerId }))
    } else if (isErrorVerification || dataPartnerVerification?.errorMessage) {
      message.error(dataPartnerVerification?.errorMessage || 'Ошибка получения данных о партнере')
    }
  }, [isSuccessVerification, isErrorVerification, dataPartnerVerification, errorVerification])

  const handleClick = () => {
    assignPartner()
  }

  if (!((isCoordinators || isCoordinator || isExternalCoordinator) && !isPartnerSupport && user?.online)) {
    return null
  }

  return (
    <Button
      loading={isLoadingVerification}
      onClick={handleClick}
    >
      Взять новую анкету
    </Button>
  )
}

export default AssignNewPartnerButton
